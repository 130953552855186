import React from 'react';
import { graphql } from 'gatsby';
import DefaultLayout from '../layouts/Default';
import Hero from '../components/CMSBlocks/Hero';
import LinkCard from '../components/NewsCard/simple';

const StatementPage = ({ data }) => {
	const allStatements = data.allDatoCmsCompanyStatement.nodes;

	return (
		<>
			<Hero title="Section 172 (1) Statements" fullWidth />
			<div className="container px-4 mx-auto my-12">
				<div className="block grid-cols-3 gap-3 lg:grid">
					{allStatements.map(
						(element) =>
							!element.hideOnStatementsPage && <LinkCard newsItem={element} />
					)}
				</div>
			</div>
		</>
	);
};

export const query = graphql`
	query companyStatementItems {
		allDatoCmsCompanyStatement {
			nodes {
				title
				slug
				hideOnStatementsPage
				contentNode {
					childMdx {
						body
					}
				}
				statementSignOff
				statementSignOffNode {
					childMdx {
						body
					}
				}
				downloadCopy {
					url
				}
				versionDate
				seo {
					title
					description
					image {
						gatsbyImageData(
							layout: CONSTRAINED
							width: 450
							imgixParams: { auto: "compress" }
						)
					}
				}
			}
		}
	}
`;

const WrappedStatementPage = (props) => (
	<DefaultLayout fullWidth>
		<StatementPage {...props} />
	</DefaultLayout>
);

export default WrappedStatementPage;
