import React from 'react';
import { Link } from 'gatsby';
import ChevronRight from '../../icons/chevron-right.svg';

const NewsCard = ({ newsItem }) => {
	const { title, slug } = newsItem;

	return (
		<div className="my-4 border border-secondary-100 lg:my-0 bg-brand-600">
			<div className="p-4">
				<h3 className="text-xl mb-4 !font-normal h-[62px] overflow-hidden">{title}</h3>

				<Link
					to={slug}
					className="flex items-center font-bold duration-75 text-brand-200 hover:text-brand-300"
				>
					<ChevronRight className="inline-block mx-2" /> Read more
				</Link>
			</div>
		</div>
	);
};

export default NewsCard;
